import { observable, action, makeAutoObservable, toJS } from "mobx";
import { makePersistable } from "mobx-persist-store";
import APIKit from "./shared/APIKit";
import { numberToMoney } from "./utils/util";

class MainStore {
  @observable auth = {
    isAuthenticated: false,
    user: {},
    loading: false,
  };
  @observable errors = {};
  @observable products = [];
  @observable offerCart = [];
  @observable current = {};
  @observable currents = [];
  @observable loading = false;
  @observable revize = false;
  @observable revizeOffer = {};
  @observable boya1 = [];
  @observable boya2 = [];
  @observable boya3 = [];
  @observable agactip = [];
  @observable logo = [];
  @observable kumas = [];
  @observable kumasKategoriler = [];
  @observable drafts = [];
  @observable loadingDrafts = true;

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "MainStore",
      properties: ["auth", "offerCart", "revize", "current"],
      storage: window.localStorage,
    });
  }

  @action deleteAllDrafts() {
    this.drafts = [];
  }

  floatParser = (data) => {
    if (parseFloat(data)) return parseFloat(data);
    else return 0;
  };

  @action async getDrafts() {
    this.loadingDrafts = true;
    const { data } = await APIKit.get("/api/draft");
    this.drafts = data.map((draft) => {
      let totalPriceWithKDV = 0;

      draft.offerCart = draft.offerCart.map((cartItem) => {
        var b = cartItem.kumasFarki
          ? cartItem.count * this.floatParser(cartItem.kumasFarki)
          : 0;
        var c = cartItem.kataForez
          ? cartItem.count * this.floatParser(cartItem.kataForez)
          : 0;
        totalPriceWithKDV +=
          (((100 - cartItem.discount3) / 100) *
            ((100 - cartItem.discount2) / 100) *
            ((100 - cartItem.discount1) / 100) *
            cartItem.price *
            cartItem.count -
            cartItem.totalDiscount +
            c +
            b) *
            (this.floatParser(cartItem.kdv) / 100) +
          (((100 - cartItem.discount3) / 100) *
            ((100 - cartItem.discount2) / 100) *
            ((100 - cartItem.discount1) / 100) *
            cartItem.price *
            cartItem.count -
            cartItem.totalDiscount +
            c +
            b);

        return cartItem;
      });

      draft["totalPriceWithKDV"] = numberToMoney(
        totalPriceWithKDV,
        draft.currenyUnit
      );

      console.log(draft);

      return draft;
    });
    this.loadingDrafts = false;
  }

  @action async deleteDraft(id) {
    const { data } = await APIKit.post("/api/draft/delete-draft", {
      id: id,
    });

    this.getDrafts();
  }

  floatParser = (data) => {
    if (parseFloat(data)) return parseFloat(data);
    else return 0;
  };

  @action useDraft(id) {
    const currentDraft = this.drafts.find((draft) => draft._id === id);

    this.offerCart = currentDraft.offerCart;
    this.revize = currentDraft.revize;
    this.current = currentDraft.current;

    console.log(currentDraft._id);

    this.deleteDraft(currentDraft._id);
  }

  @action async addDraft(draft) {
    const { data } = await APIKit.post("/api/draft", draft);
    this.revize = false;
    this.offerCart = [];
    this.current = {};

    this.getDrafts();
  }

  @action setKumasKategoriler(values) {
    this.kumasKategoriler = values;
  }

  @action setKumas(values) {
    this.kumas = values;
  }

  @action setOtherSpecs(values) {
    this.agactip = values.agactip;
    this.boya1 = values.boya1;
    this.boya2 = values.boya2;
    this.logo = values.logo;
    this.boya3 = values.boya3;
  }

  @action setAuth(auth) {
    this.auth.user = auth;
    this.auth.isAuthenticated = true;
  }

  @action logOut() {
    this.auth = {
      isAuthenticated: false,
      user: {},
      loading: false,
    };
  }

  //PRODUCTS START

  @action setProducts(values) {
    this.products = values;
  }

  @action addProduct(values) {
    Object.entries(values).map((value) => {
      var a = this.products[value[0]] ? this.products[value[0]] : [];

      value[1].map((prd) => {
        a.push(prd);
      });

      this.products[value[0]] = a;
    });
  }

  @action getTotalProductCount() {
    var a = 0;
    Object.values(this.products).map((group) => {
      a += group.length;
    });

    return a;
  }
  //PRODUCTS END

  //OFFER CART START

  @action addToCart(values) {
    this.offerCart = [...this.offerCart, values];
  }

  @action updateCartItem(newData, i) {
    this.offerCart[i] = newData;
  }

  @action removeFromCart(index) {
    const tempOffer = toJS(this.offerCart);
    const filtered = tempOffer.filter((a, i) => i !== index);
    this.setOfferCart([]);
    setTimeout(() => {
      this.offerCart = filtered;
    }, 50);
  }

  compare = (a, b) => {
    if (parseInt(a.sortNumber) < parseInt(b.sortNumber)) {
      return 1;
    }
    if (parseInt(a.sortNumber) > parseInt(b.sortNumber)) {
      return -1;
    }
    return 0;
  };

  @action addToCartNewItem() {
    this.offerCart = [
      ...this.offerCart,
      {
        discount1: 0,
        discount2: 0,
        discount3: 0,
        price: 0,
        totalDiscount: 0,
        totalPrice: 0,
        ahsapmetal: "",
        derikumas: "",
        kumasmus: false,
        kdv: 0,
        aciklama: "",
        count: 1,
        lstFiyat: 0,
        isNew: true,
        grupadi: "TM MASA",
        images: [],
        files: [],
        kdv: 10,
        ozel1: "",
        mlzadi2: "",
        ozel2: "",
        kumasKat: "",
        kumasTip: "",
        kumasGrup: "",
        ozel3: "",
        webstkno: Math.round(new Date().getTime() / 1000),
        sortNumber: this.offerCart.length / 10,
        stkno: "",
        stokadi: "",
        agactipi: "",
        boya1: "",
        boya2: "",
        logo: "",
        boya3: "",
        uruntipi: "",
        combination: null,
        kumas: {
          kod: "kumas",
          label: "Kumaş",
          value: "",
        },
        combinationData: {},
        configuration: {},
        kumasFarki: 0,
        kataForez: 0,
      },
    ];
  }
  @action deleteAllCart() {
    this.offerCart = [];
  }

  @action upOfferCart(i) {
    if (typeof this.offerCart[i].sortNumber !== "number")
      this.offerCart[i].sortNumber = this.offerCart.length;

    this.offerCart[i].sortNumber = parseInt(this.offerCart[i].sortNumber) + 1;

    var sorted = this.offerCart.sort(this.compare);

    this.offerCart = [];

    setTimeout(() => {
      this.offerCart = sorted;
    }, 50);
  }

  @action downOfferCart(i) {
    if (typeof this.offerCart[i].sortNumber !== "number")
      this.offerCart[i].sortNumber = this.offerCart.length;
    this.offerCart[i].sortNumber = this.offerCart[i].sortNumber - 1;

    var sorted = this.offerCart.sort(this.compare);

    this.offerCart = [];

    setTimeout(() => {
      this.offerCart = sorted;
    }, 50);
  }

  @action setOfferCart(values) {
    for (var i = 0; i < this.offerCart.length; i++) {
      this.offerCart[i].sortNumber = i;
    }
    this.offerCart = values;
  }
  //OFFER CART END

  //CURRENT START

  @action setCurrent(value) {
    this.current = value;
  }
  //CURRENT END

  //CURRENTS START

  @action setCurrents(values) {
    this.currents = values;
  }
  //CURRENTS END

  //LOADING START

  @action setLoading(value) {
    this.loading = value;
  }

  //LOADING END

  //REVIZE START

  @action setRevize(value) {
    this.revize = value;
  }
  //REVIZE END

  //REVIZE OFFER START

  @action setRevizeOffer(values) {
    this.revizeOffer = values;
  }
  //REVIZE OFFER END
}

const store = new MainStore();
export default store;
