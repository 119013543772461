import React from "react";
import styles from "./offerdetails.module.css";
import { Button, Input, message, Modal } from "antd";
import { numberToMoney } from "../../utils/util";
import APIKit from "../../shared/APIKit";
import { useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import OfferConfirmation from "./offerConfirmation/OfferConfirmation";
import LoadingOverlay from "react-loading-overlay";
import ReactToPrint from "react-to-print";

const OfferDetails = inject("MainStore")(
  observer(
    ({
      isOpen,
      setIsOpen,
      selectedOffer,
      currenyUnit,
      withDiscountPrice,
      withoutDiscountPrice,
      discount,
      offerId,
      setLoading,
      loadData,
      status,
      MainStore,
      printIt,
      project,
      setProject,
    }) => {
      const [explanation, setExplanation] = React.useState("");
      const [isOpenConfirmation, setIsOpenConfirmation] =
        React.useState(project);
      const { url } = useRouteMatch();
      const { TextArea } = Input;
      const [loadingOverlay, setLoadingOverlay] = React.useState(false);
      const history = useHistory();
      const floatParser = (data) => {
        if (parseFloat(data)) return parseFloat(data);
        else return 0;
      };


  const returnImageUrl = (url) => {
    if(url) {
      const regex = /(\d+\.jpg)$/;
      const match = url.match(regex);
  
      if (match) {
        return "https://selective-bucket.s3.eu-north-1.amazonaws.com/leta/images/"+match[1];
      } else {
        return ''
      }
    }else {
      return ''
    }
    
  }

      const setConfirmProject = () => {
        setLoadingOverlay(true);
        APIKit.post("/api/project/createproject", {
          status: "Siparişe Dönüştü",
          project: selectedOffer,
        })
          .then((res) => {
            setLoadingOverlay(false);
            loadData();
            setIsOpen(false);
            setProject(false);
            setIsOpenConfirmation(false);
            message.success("Teklif Siparişe Dönüştü!");
          })
          .catch((err) => {
            setLoadingOverlay(false);
            if (err?.response?.data) message.error(err.response.data);
            else message.error("Teklif siparişe dönüştürülemedi!");
          });
      };

      return (
        <Modal
          title="Teklif Detaylar"
          visible={isOpen.detaylar}
          width={1000}
          zIndex={100}
          onCancel={() => {
            setProject(false);
            setIsOpen({ ...isOpen, detaylar: false });
          }}
          footer={false}
        >
          <LoadingOverlay
            active={loadingOverlay}
            spinner
            text={"Yükleniyor..."}
          >
            <div className={styles.container}>
              {status === "Reddedildi" ? (
                <div className={styles.top}>
                  Red Sebebi:{selectedOffer.disExplanation}
                </div>
              ) : selectedOffer.revizeCount > 0 ? (
                <div
                  className={styles.top}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <span>
                    Revize Sebebi:{" "}
                    {
                      selectedOffer.offers[
                        Object.keys(selectedOffer.offers).length - 1
                      ][0].revizeExplanation
                    }
                  </span>
                  <span>
                    Revize eden :{" "}
                    {
                      selectedOffer.offers[
                        Object.keys(selectedOffer.offers).length - 1
                      ][0].whoDidRevize
                    }
                  </span>
                </div>
              ) : null}
              <div className={styles.mainContainer}>
                {selectedOffer.offers[
                  Object.keys(selectedOffer.offers).length - 1
                ].map((offer, i) => {
                  return (
                    <div key={i} className={styles.offerContainer}>
                      <div className={styles.imgContainer}>
                        {offer.images ? (
                          <img
                            src={returnImageUrl(offer.images[0] ? offer.images[0].url : "")}
                          />
                        ) : (
                          <img src={""} />
                        )}
                      </div>
                      <div className={styles.offerItem}>
                        <div className={styles.row}>
                          <span>
                            {offer.stkno ? "Stok Kodu: " : "Web Stok Kodu: "}
                            {offer.stkno ? offer.stkno : offer.webstkno}
                          </span>
                          <span>Stok Adı: {offer.stokadi}</span>
                        </div>
                        <div className={styles.row}>
                          <span>Deri/Kumaş Detayı: {offer.derikumas}</span>
                          <span>Ahşap/Metal Detayı: {offer.ahsapmetal}</span>
                          <span>Ağaç Tipi: {offer.agactipi}</span>
                        </div>
                        <div className={styles.row}>
                          <span>
                            Seçili Kombinasyon:{" "}
                            {offer.combination ? offer.combination : "Yok"}
                          </span>

                          {offer.combinationData !== undefined
                            ? Object.values(offer.combinationData).map(
                                (comb, i) => {
                                  return (
                                    <span key={i} style={{ minWidth: 230 }}>
                                      {comb.label}:{" "}
                                      {comb["name"] ? comb.name : comb.value}
                                    </span>
                                  );
                                }
                              )
                            : null}
                        </div>
                        <div className={styles.row}>
                          <span>Teknik Açıklama: {offer.aciklama}</span>
                        </div>
                        <div className={styles.row}>
                          <span>Boya1: {offer.boya1}</span>
                          <span>Boya2: {offer.boya2}</span>
                          <span>Boya3: {offer.boya3}</span>
                          <span>
                            Kataforez Farkı:{" "}
                            {numberToMoney(offer.kataForez, currenyUnit)}
                          </span>
                          <span>
                            Kumaş Farkı:{" "}
                            {numberToMoney(offer.kumasFarki, currenyUnit)}
                          </span>
                        </div>
                        <div className={styles.row}>
                          <span>
                            Fiyat: {numberToMoney(offer.price, currenyUnit)}
                          </span>
                          <span>Adet: {offer.count}</span>

                          <span>KDV: {offer.kdv + "%"}</span>
                          {offer.discount1 ? (
                            <span>İsk1: {offer.discount1 + "%"}</span>
                          ) : (
                            ""
                          )}
                          {offer.discount2 ? (
                            <span>İsk2: {offer.discount2 + "%"}</span>
                          ) : (
                            ""
                          )}
                          {offer.discount3 ? (
                            <span>İsk3: {offer.discount3 + "%"}</span>
                          ) : (
                            ""
                          )}
                          <span>
                            Kalem İskonto:{" "}
                            {offer.totalDiscount > 0
                              ? offer.totalDiscount
                              : 0 + currenyUnit}
                          </span>
                        </div>
                        <div className={styles.row}>
                          <span>
                            Ham Fiyat:{" "}
                            {numberToMoney(
                              offer.price * offer.count,
                              currenyUnit
                            )}
                          </span>
                          <span>
                            Toplam İskonto:{" "}
                            {offer.price * offer.count -
                              ((((100 - offer.discount3) / 100) *
                                ((100 - offer.discount2) / 100) *
                                ((100 - offer.discount1) / 100) *
                                offer.price *
                                offer.count -
                                offer.totalDiscount +
                                floatParser(offer.kataForez) +
                                floatParser(offer.kumasFarki)) *
                                (floatParser(offer.kdv) / 100) +
                                (((100 - offer.discount3) / 100) *
                                  ((100 - offer.discount2) / 100) *
                                  ((100 - offer.discount1) / 100) *
                                  offer.price *
                                  offer.count -
                                  offer.totalDiscount +
                                  floatParser(offer.kataForez) +
                                  floatParser(offer.kumasFarki))) >
                            0
                              ? numberToMoney(
                                  offer.price * offer.count -
                                    ((((100 - offer.discount3) / 100) *
                                      ((100 - offer.discount2) / 100) *
                                      ((100 - offer.discount1) / 100) *
                                      offer.price *
                                      offer.count -
                                      offer.totalDiscount +
                                      floatParser(offer.kataForez) +
                                      floatParser(offer.kumasFarki)) *
                                      (floatParser(offer.kdv) / 100) +
                                      (((100 - offer.discount3) / 100) *
                                        ((100 - offer.discount2) / 100) *
                                        ((100 - offer.discount1) / 100) *
                                        offer.price *
                                        offer.count -
                                        offer.totalDiscount +
                                        floatParser(offer.kataForez) +
                                        floatParser(offer.kumasFarki))),
                                  currenyUnit
                                )
                              : 0}
                          </span>
                          <span>
                            Genel Toplam:{" "}
                            {String(
                              numberToMoney(
                                (((100 - offer.discount3) / 100) *
                                  ((100 - offer.discount2) / 100) *
                                  ((100 - offer.discount1) / 100) *
                                  offer.price *
                                  offer.count -
                                  offer.totalDiscount +
                                  floatParser(offer.kataForez) * offer.count +
                                  floatParser(offer.kumasFarki) * offer.count) *
                                  (floatParser(offer.kdv) / 100) +
                                  (((100 - offer.discount3) / 100) *
                                    ((100 - offer.discount2) / 100) *
                                    ((100 - offer.discount1) / 100) *
                                    offer.price *
                                    offer.count -
                                    offer.totalDiscount +
                                    floatParser(offer.kataForez) * offer.count +
                                    floatParser(offer.kumasFarki) *
                                      offer.count),
                                currenyUnit
                              )
                            )}
                          </span>
                        </div>
                        <div className={styles.row}>
                          {offer.files[0] ? (
                            <div className={styles.attachment}>
                              Ek Dosyalar
                              <div className={styles.buttons}>
                                {offer.files.map((file, i) => {
                                  return (
                                    <a key={i} href={file.url} target="_blank">
                                      Ek {i + 1}
                                    </a>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.totalBar}>
                <div className={styles.row}>
                  <span className={styles.price}>
                    Ham Fiyat: {withoutDiscountPrice}
                  </span>
                  <span className={styles.price}>
                    Toplam İskonto: {discount}
                  </span>
                  <span className={styles.price}>
                    Toplam Fiyat: {withDiscountPrice}
                  </span>
                </div>

                {status === "Onayda Bekleyen" ||
                status === "Onaylandı (Projesiz)" ||
                status === "Onaylandı (Projeli)" ? (
                  MainStore.auth.user.magaza === "admin" ||
                  MainStore.auth.user.gorev === "admin" ||
                  MainStore.auth.user.gorev === "Müdür" ? (
                    <>
                      <div className={styles.row}>
                        <TextArea
                          onChange={(e) => setExplanation(e.target.value)}
                          style={{ width: "100%", margin: 5 }}
                          placeholder={
                            "Teklif Red Açıklaması (Reddedileceği zaman doldurulması zorunludur!)"
                          }
                        />
                      </div>
                      <div className={styles.row}>
                        <Button
                          style={{
                            backgroundColor: "var(--secondary-color)",
                            color: "white",
                            borderRadius: 15,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setIsOpenConfirmation(true);
                          }}
                        >
                          {status === "Onaylandı (Projeli)" ||
                          status === "Onaylandı (Projesiz)" ? (
                            <span style={{ marginRight: 50 }}>
                              Siparişe Dönüştür{" "}
                            </span>
                          ) : (
                            "Onayla"
                          )}
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "var(--primary-color)",
                            color: "white",

                            borderRadius: 15,
                          }}
                          onClick={() => {
                            if (explanation)
                              APIKit.post("api/offer/status", {
                                offerId: offerId,
                                status: "Reddedildi",
                                explanation: explanation,
                              })
                                .then((res) => {
                                  message.success("Teklif Reddedildi.");
                                  setLoading(true);
                                  loadData();
                                  setIsOpen(false);
                                  setProject(false);
                                  setConfirmProject(false);
                                })
                                .catch((err) => {
                                  message.error("Teklif Onaylanamadı!");
                                });
                            else message.error("Gerekli alanlar doldurulmalı!");
                          }}
                        >
                          Reddet
                        </Button>
                        {status !== "Onaylandı (Projesiz)" &&
                          status !== "Onaylandı (Projeli)" && (
                            <Button
                              onClick={() => {
                                MainStore.setRevize(true);
                                MainStore.setRevizeOffer(selectedOffer);
                                MainStore.setCurrent(selectedOffer.current);
                                MainStore.setOfferCart(
                                  selectedOffer.offers[
                                    Object.keys(selectedOffer.offers).length - 1
                                  ]
                                );

                                setIsOpen(false);
                                setConfirmProject(false);
                                setProject(false);
                                message.info(
                                  "Teklif ekranından revize işlemine devam edebilirsiniz."
                                );
                              }}
                              style={{ borderRadius: 15 }}
                            >
                              Revize Et
                            </Button>
                          )}
                        <Button
                          disabled={selectedOffer ? false : true}
                          onClick={() => printIt()}
                          style={{
                            backgroundColor: "var(--secondary-color)",
                            color: "white",
                            borderRadius: 15,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Çıktı Al
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className={styles.row}>
                      <Button style={{ borderRadius: 15 }}>Revize Et</Button>

                      <Button
                        disabled={selectedOffer ? false : true}
                        onClick={() => printIt()}
                        style={{ borderRadius: 15 }}
                      >
                        Çıktı Al
                      </Button>
                    </div>
                  )
                ) : (
                  <Button
                    disabled={selectedOffer ? false : true}
                    onClick={() => printIt()}
                    style={{ borderRadius: 15 }}
                  >
                    Çıktı Al
                  </Button>
                )}
              </div>
            </div>
            <OfferConfirmation
              isOpen={project ? project : isOpenConfirmation}
              setIsOpen={setIsOpenConfirmation}
              setProject={setProject}
              current={selectedOffer.current}
              offer={selectedOffer}
              setConfirmProject={setConfirmProject}
              status={status}
              setTopOpen={setIsOpen}
              loadData={loadData}
            />
          </LoadingOverlay>
        </Modal>
      );
    }
  )
);

export default OfferDetails;
