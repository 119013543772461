import React from "react";
import styles from "./OfferCart.module.css";
import OfferItem from "../../components/offerCartItem/offerCartItem";
import {
  Input,
  Button,
  Select,
  Popconfirm,
  message,
  Modal,
  Divider,
  Checkbox,
} from "antd";
import { FaFirstdraft } from "react-icons/fa";
import PrintPDF from "../../components/printOfferCart/PrintPDF";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import {
  ADD_CART_NEW_ITEM,
  DELETE_CART,
  GET_CART,
  REVIZE,
  REVIZE_OFFER,
  SET_LOADING,
} from "../../actions/types";
import { FaPlus } from "react-icons/fa";
import { numberToMoney } from "../../utils/util";
import APIKit from "../../shared/APIKit";
import moment from "moment";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";

const OfferCart = inject("MainStore")(
  observer(({ MainStore }) => {
    const [refresh, setRefresh] = React.useState(false);
    const [offerDetails, setOfferDetails] = React.useState({
      defaultMoney: MainStore.current?.parabirimi
        ? MainStore.current?.parabirimi
        : " TL",
      offerName: "T" + Math.floor(Date.now() / 1000),
      explanation: "",
    });
    const [currenyUnits, setCurrenyUnits] = React.useState([
      "TL",
      "EURO",
      "USD",
    ]);
    const [newValue, setNewValue] = React.useState("");
    const [totalCartPrice, setTotalCartPrice] = React.useState(0);
    const [nativeCartPrice, setNativeCartPrice] = React.useState(0);
    const [cartPriceWithoutKdv, setCartPriceWithoutKdv] = React.useState(0);
    const [revizeExplanation, setRevizeExplanation] = React.useState("");
    const [mountedCart, setMountedCart] = React.useState([]);
    const [beforePrint, setBeforePrint] = React.useState(false);
    const [printInfo, setPrintInfo] = React.useState({
      lang: "tr",
      hide: false,
      type: "DİĞER",
      type2: "SEÇİM YOK",
      ulasmaSekli: "Sosyal Medya",
    });
    const history = useHistory();
    const { TextArea } = Input;
    const { Option } = Select;
    var printRef = React.useRef();
    let index = 0;
    const { confirm } = Modal;

    const printIt = useReactToPrint({
      content: () => printRef,
    });

    const floatParser = (data) => {
      if (parseFloat(data)) return parseFloat(data);
      else return 0;
    };

    const addItem = () => {
      if (newValue) {
        setCurrenyUnits([...currenyUnits, newValue]);
        setOfferDetails({ ...offerDetails, defaultMoney: newValue });
      } else message.error("Alan boş bıraklamaz!");
    };

    React.useEffect(() => {
      var native = 0;
      for (var i = 0; i < Object.keys(MainStore.offerCart).length; i++) {
        native += MainStore.offerCart[i].price * MainStore.offerCart[i].count;
      }

      setNativeCartPrice(native);

      var a = 0;
      var withoutKdvPrice = 0;
      for (var i = 0; i < Object.keys(MainStore.offerCart).length; i++) {
        var b = MainStore.offerCart[i].kumasFarki
          ? MainStore.offerCart[i].count *
            floatParser(MainStore.offerCart[i].kumasFarki)
          : 0;
        var c = MainStore.offerCart[i].kataForez
          ? MainStore.offerCart[i].count *
            floatParser(MainStore.offerCart[i].kataForez)
          : 0;
        withoutKdvPrice +=
          ((100 - MainStore.offerCart[i].discount3) / 100) *
            ((100 - MainStore.offerCart[i].discount2) / 100) *
            ((100 - MainStore.offerCart[i].discount1) / 100) *
            MainStore.offerCart[i].price *
            MainStore.offerCart[i].count -
          MainStore.offerCart[i].totalDiscount +
          c +
          b;
        setCartPriceWithoutKdv(withoutKdvPrice);
        a +=
          (((100 - MainStore.offerCart[i].discount3) / 100) *
            ((100 - MainStore.offerCart[i].discount2) / 100) *
            ((100 - MainStore.offerCart[i].discount1) / 100) *
            MainStore.offerCart[i].price *
            MainStore.offerCart[i].count -
            MainStore.offerCart[i].totalDiscount +
            c +
            b) *
            (floatParser(MainStore.offerCart[i].kdv) / 100) +
          (((100 - MainStore.offerCart[i].discount3) / 100) *
            ((100 - MainStore.offerCart[i].discount2) / 100) *
            ((100 - MainStore.offerCart[i].discount1) / 100) *
            MainStore.offerCart[i].price *
            MainStore.offerCart[i].count -
            MainStore.offerCart[i].totalDiscount +
            c +
            b);
      }
      setTotalCartPrice(a);
    }, [MainStore.offerCart, refresh, setRefresh]);

    const makeRevize = () => {
      if (!revizeExplanation) {
        message.error("Revize açıklaması zorunludur!");
      } else {
        MainStore.setLoading(true);

        var newOfferCart = MainStore.offerCart;
        for (var i = 0; i < MainStore.offerCart.length; i++) {
          newOfferCart[i].configuration = {};
          newOfferCart[i]["revizeExplanation"] = revizeExplanation;
          newOfferCart[i]["revizeDate"] = moment();
          newOfferCart[i]["whoDidRevize"] = MainStore.auth.user.username;
        }

        MainStore.setOfferCart([]);
        MainStore.setRevize(false);

        APIKit.post("/api/offer/revize", {
          offerId: MainStore.revizeOffer._id,
          newOffer: newOfferCart,
        })
          .then((res) => {
            MainStore.setLoading(false);

            message.success("Revize edildi!");

            MainStore.setOfferCart([]);
            MainStore.setRevize(false);
          })
          .catch((err) => {
            MainStore.setLoading(false);

            message.error("Revize edilemedi!");
          });
      }
    };

    const createOffer = () => {
      if (!offerDetails.defaultMoney || !offerDetails.offerName)
        message.error("Alanlar boş bırakılamaz!");
      else {
        MainStore.setLoading(true);

        var newOfferCart = MainStore.offerCart;
        for (var i = 0; i < MainStore.offerCart.length; i++) {
          newOfferCart[i].configuration = {};
          newOfferCart[i]["revizeDate"] = moment();
        }

        MainStore.setOfferCart([]);
        MainStore.setRevize(false);

        APIKit.post("/api/offer", {
          offerName: offerDetails.offerName,
          explanation: offerDetails.explanation,
          currenyUnit: offerDetails.defaultMoney,
          offerCart: newOfferCart,
          ulasmaSekli: printInfo.ulasmaSekli,
          current: MainStore.current,
        })
          .then((res) => {
            MainStore.setLoading(false);

            message.success("Teklif oluşturuldu!");
            MainStore.setOfferCart([]);
            MainStore.setRevize(false);
          })
          .catch((err) => {
            MainStore.setLoading(true);
            message.error("Teklif oluşturulamadı!");
          });
      }
    };

    const compare = (a, b) => {
      if (parseInt(a.sortNumber) < parseInt(b.sortNumber)) {
        return 1;
      }
      if (parseInt(a.sortNumber) > parseInt(b.sortNumber)) {
        return -1;
      }
      return 0;
    };

    return (
      <div className={styles.container}>
        {Object.keys(MainStore.offerCart).length === 0 ? (
          <div className={styles.notFound}>
            <FaFirstdraft className={styles.icon} />
            <span>Teklifiniz Boş!</span>
          </div>
        ) : (
          <>
            <div className={styles.header}>
              <Popconfirm
                placement="bottom"
                title={"İptal etmek istediğinizden eminmisiniz?"}
                onConfirm={() => {
                  if (MainStore.revize) {
                    MainStore.setRevize(false);
                    MainStore.setRevizeOffer([]);
                  }
                  MainStore.setOfferCart([]);

                  message.success(
                    MainStore.revize
                      ? "Revize iptal edildi!"
                      : "Teklif iptal edildi!"
                  );
                }}
                okText="Evet"
                cancelText="Hayır"
              >
                <Button className={styles.buttonClassic}>
                  {MainStore.revize ? "Revizeyi iptal et" : "Teklifi İptal Et"}
                </Button>
              </Popconfirm>

              <Input
                value={
                  MainStore.revize
                    ? MainStore.revizeOffer.offerName
                    : offerDetails.offerName
                }
                onChange={(e) => {
                  if (!MainStore.revize) {
                    setOfferDetails({
                      ...offerDetails,
                      offerName: e.target.value,
                    });
                  }
                }}
                disabled={MainStore.revize}
                className={styles.input}
                placeholder={"Teklif Adı"}
              />
              {MainStore.revize ? (
                <TextArea
                  placeholder={"Revize Açıklaması"}
                  className={styles.input}
                  value={revizeExplanation}
                  onChange={(data) => setRevizeExplanation(data.target.value)}
                />
              ) : null}

              <Input
                disabled={true}
                className={styles.input}
                placeholder={
                  MainStore?.current?.grup
                    ? "Müşteri Tipi: " + MainStore?.current?.grup
                    : "Müşteri Tipi Yok"
                }
              />
              <Select
                onChange={(value) => {
                  if (!MainStore.revize)
                    setOfferDetails({ ...offerDetails, defaultMoney: value });
                }}
                value={
                  MainStore.revize
                    ? MainStore.revizeOffer.currenyUnit
                    : offerDetails.defaultMoney
                }
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                        flexDirection: "column",
                      }}
                    >
                      <Input
                        style={{ flex: "auto" }}
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                      />
                      <a
                        style={{
                          flex: "none",
                          padding: "8px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={addItem}
                      >
                        <AiOutlinePlus /> Yeni ekle
                      </a>
                    </div>
                  </div>
                )}
                placeholder={"Para Birimi"}
                disabled={true}
                className={styles.select}
              >
                {currenyUnits.map((cu, i) => {
                  return (
                    <Option key={i} value={cu}>
                      {cu}
                    </Option>
                  );
                })}
              </Select>
              <Select
                onChange={(value) => {
                  if (!MainStore.revize)
                    setOfferDetails({ ...offerDetails, defaultMoney: value });
                }}
                value={
                  MainStore.revize
                    ? MainStore.revizeOffer.currenyUnit
                    : offerDetails.defaultMoney
                }
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                        flexDirection: "column",
                      }}
                    >
                      <Input
                        style={{ flex: "auto" }}
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                      />
                      <a
                        style={{
                          flex: "none",
                          padding: "8px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={addItem}
                      >
                        <AiOutlinePlus /> Yeni ekle
                      </a>
                    </div>
                  </div>
                )}
                placeholder={"Para Birimi"}
                disabled={true}
                className={styles.select}
              >
                {currenyUnits.map((cu, i) => {
                  return (
                    <Option key={i} value={cu}>
                      {cu}
                    </Option>
                  );
                })}
              </Select>
              <TextArea
                disabled={MainStore.revize}
                onChange={(e) => {
                  if (!MainStore.revize)
                    setOfferDetails({
                      ...offerDetails,
                      explanation: e.target.value,
                    });
                }}
                className={styles.textArea}
                placeholder={"Açıklama"}
              />
            </div>

            <div className={styles.content}>
              {/* .sort(compare) */}
              {MainStore.offerCart.map((offer, index) => {
                return (
                  <OfferItem
                    offerCart={MainStore.offerCart}
                    key={index}
                    mountedCart={mountedCart}
                    setMountedCart={setMountedCart}
                    product={offer}
                    index={index}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    defaultMoney={offerDetails.defaultMoney}
                  />
                );
              })}
            </div>
          </>
        )}
        <div className={styles.footer}>
          <div className={styles.right}>
            <div className={styles.rightIn}>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  if (Object.keys(MainStore.current).length) {
                    MainStore.addToCartNewItem();
                  } else message.info("Cari seçmelisiniz!");
                }}
              >
                <FaPlus style={{ marginRight: 10 }} /> Yeni Ürün Ekle
              </Button>
            </div>
            {Object.keys(MainStore.offerCart).length !== 0 ? (
              <>
                <div className={styles.rightIn}>
                  <label>Ara Toplam:</label>{" "}
                  <Input
                    disabled={true}
                    value={String(
                      numberToMoney(nativeCartPrice, "") +
                        offerDetails.defaultMoney
                    )}
                  />
                </div>
                <div className={styles.rightIn}>
                  <label>Toplam İskonto:</label>{" "}
                  <Input
                    disabled={true}
                    value={String(
                      parseInt(
                        numberToMoney(nativeCartPrice - totalCartPrice, "")
                      ) > 0
                        ? numberToMoney(
                            nativeCartPrice - totalCartPrice,
                            offerDetails.defaultMoney
                          )
                        : 0 + offerDetails.defaultMoney
                    )}
                  />
                </div>
                <div className={styles.rightIn}>
                  <label>Genel Toplam:</label>{" "}
                  <Input
                    disabled={true}
                    value={String(
                      numberToMoney(totalCartPrice, "") +
                        offerDetails.defaultMoney
                    )}
                  />
                </div>
                <div className={styles.rightIn}>
                  <Button
                    onClick={() => {
                      if (MainStore.current) {
                        var newOfferCart = MainStore.offerCart;
                        for (var i = 0; i < MainStore.offerCart.length; i++) {
                          newOfferCart[i].configuration = {};
                          newOfferCart[i]["revizeDate"] = moment();
                        }

                        MainStore.addDraft({
                          offerName: offerDetails.offerName,
                          explanation: offerDetails.explanation,
                          currenyUnit: offerDetails.defaultMoney,
                          offerCart: newOfferCart,
                          current: MainStore.current,
                          draftKey: Math.floor(Date.now() / 1000),
                        });
                        history.push("/home/drafts");
                      } else {
                        message.info("Cari Seçmelisiniz!");
                      }
                    }}
                    className={[styles.buttonClassic]}
                    style={{
                      backgroundColor: "rgba(0,0,0,0.2)",
                      color: "white",
                    }}
                  >
                    Taslaklara At
                  </Button>
                  <div style={{ opacity: 0 }}>
                    <ReactToPrint
                      ref={printRef}
                      onAfterPrint={() => {
                        confirm({
                          title: !MainStore.revize
                            ? "Teklifi oluşturmak istiyormusunuz?"
                            : "Revize etmek istiyormusunuz!",
                          icon: (
                            <AiOutlineInfoCircle
                              style={{ width: 40, height: 40 }}
                            />
                          ),
                          content: "",
                          okText: "Evet",
                          cancelText: "Hayır",
                          onOk() {
                            //if (!MainStore.revize) createOffer();
                            //else makeRevize();
                          },
                          onCancel() {
                            message.info("Teklif oluşturulmadı!");
                          },
                        });
                      }}
                      trigger={() => (
                        <Button
                          onClick={() => {}}
                          className={styles.buttonClassic}
                        >
                          {MainStore.revize ? "Revize Et" : "Teklif Oluştur"}
                        </Button>
                      )}
                      content={() => printRef}
                    />
                  </div>
                  <Button
                    onClick={() => setBeforePrint(true)}
                    className={styles.buttonClassic}
                  >
                    {MainStore.revize ? "Revize Et" : "Teklif Oluştur"}
                  </Button>
                </div>
                <div style={{ display: "none" }}>
                  <PrintPDF
                    ref={(el) => (printRef = el)}
                    orderCart={MainStore.offerCart}
                    offerDetails={offerDetails}
                    totalCartPrice={totalCartPrice}
                    auth={MainStore.auth.user}
                    printInfo={printInfo}
                    nativeCartPrice={nativeCartPrice}
                    currenyUnit={offerDetails.defaultMoney}
                    current={MainStore.current}
                    cartPriceWithoutKdv={cartPriceWithoutKdv}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        <Modal
          title="Çıktı Ayarları"
          visible={beforePrint}
          width={400}
          okText={"Çıktı Al"}
          cancelText={"İptal Et"}
          onOk={() => {
            setBeforePrint(false);

            printIt();

            setTimeout(() => {
              confirm({
                title: !MainStore.revize
                  ? "Teklifi oluşturmak istiyormusunuz?"
                  : "Revize etmek istiyormusunuz!",
                icon: <AiOutlineInfoCircle style={{ width: 40, height: 40 }} />,
                content: "",
                okText: "Evet",
                cancelText: "Hayır",
                onOk() {
                  if (!MainStore.revize) createOffer();
                  else makeRevize();
                },
                onCancel() {
                  message.info("Teklif oluşturulmadı!");
                },
              });
            }, 1500);
          }}
          onCancel={() => setBeforePrint(false)}
        >
          <div style={{ width: "100%" }}>
            <Checkbox
              style={{ width: "100%" }}
              value={printInfo.hide}
              onChange={(value) =>
                setPrintInfo({ ...printInfo, hide: !printInfo.hide })
              }
            >
              İskonto oranı ve iskonto tutarını gizle
            </Checkbox>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              Tipi:
              <Select
                style={{ width: "100%", marginLeft: 15 }}
                value={printInfo.type}
                onSelect={(v) => setPrintInfo({ ...printInfo, type: v })}
                placeholder={"Çıktı Tipi"}
                options={[
                  {
                    label: "İHRACAT",
                    value: "İHRACAT",
                  },
                  {
                    label: "YURTİÇİ",
                    value: "YURTİÇİ",
                  },
                  {
                    label: "İHRAÇ KAYITLI",
                    value: "İHRAÇ KAYITLI",
                  },
                  {
                    label: "DİĞER",
                    value: "DİĞER",
                  },
                ]}
                onChange={(value) =>
                  setPrintInfo({ ...printInfo, hide: !printInfo.hide })
                }
              >
                İskonto oranı ve iskonto tutarını gizle
              </Select>
            </div>
          </div>

          {printInfo.type === "İHRACAT" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              Kullanılacak Hesap:
              <Select
                value={printInfo.type2}
                style={{ width: "100%", marginLeft: 15 }}
                onChange={(value) =>
                  setPrintInfo({ ...printInfo, type2: value })
                }
              >
                <Select.Option value={"LETA PROJE"}>LETA PROJE</Select.Option>
                <Select.Option value={"ÖZCANLAR"}>ÖZCANLAR</Select.Option>
                <Select.Option value={"SEÇİM YOK"}>SEÇİM YOK</Select.Option>
              </Select>
            </div>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            Dil:
            <Select
              value={printInfo.lang}
              style={{ width: "100%", marginLeft: 15 }}
              onChange={(value) => setPrintInfo({ ...printInfo, lang: value })}
            >
              <Select.Option value={"tr"}>Türkçe</Select.Option>
              <Select.Option value={"en"}>İngilizce</Select.Option>
            </Select>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            Ulaşma Şekli:
            <Select
              value={printInfo.ulasmaSekli}
              style={{ width: "100%", marginLeft: 15 }}
              onChange={(value) => setPrintInfo({ ...printInfo, ulasmaSekli: value })}
            >
              <Select.Option value={"Sosyal Medya"}>Sosyal Medya</Select.Option>
              <Select.Option value={"Mağaza"}>Mağaza</Select.Option>
              <Select.Option value={"Yönetim"}>Yönetim</Select.Option>
              <Select.Option value={"Eski Müşteri"}>Eski Müşteri</Select.Option>
            </Select>
          </div>
        </Modal>
      </div>
    );
  })
);

export default OfferCart;
